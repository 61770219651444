import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled, { css } from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import MDXComponents from 'src/components/mdx-components';
import { Column, Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { FluidImg } from 'src/embeds/image/image-dato';

const MonoCategory = styled.div`
  p {
    margin: 0;
    font-size: 14px;
  }
`;

const RenderContainer = styled.div`
  p {
    color: #6d708b;
  }
`;

const JourneyContainer = styled.div`
  background: #f2f4fa;
`;

const ColumnOrder = styled(Column)`
  @media (max-width: 799px) {
    order: 1;
  }
  ${({ order }) =>
    order &&
    css`
      order: ${order};
    `}
`;

export default function Drawer({ callouts, content, heading, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <JourneyContainer>
        <Section {...customData?.flags} transparent={true}>
          <h2>{heading}</h2>
          <p style={{ whiteSpace: 'pre-line' }}>{content}</p>
          {callouts.map((callout, i) => {
            const customData = JSON.parse(callout.customData);
            return (
              <Card key={i} secondary flat>
                <Grid columns="repeat(2, minmax(0,1fr))" verticalAlign="center">
                  <ColumnOrder order={i % 2}>
                    <MonoCategory style={{ color: '#000' }} className="f-mono">
                      {callout?.preHeading?.toUpperCase()}
                    </MonoCategory>
                    {!!callout.heading && <h2 style={{ color: '#000' }}>{callout.heading}</h2>}
                    {callout.content && (
                      <RenderContainer>
                        <MDXRenderer style={{ color: '#000' }}>
                          {callout.contentNode.childMdx.body}
                        </MDXRenderer>
                      </RenderContainer>
                    )}
                  </ColumnOrder>
                  <Column>
                    <Card zeroPadding>
                      {callout.ctas?.length > 0 && (
                        <>
                          {callout.media ? (
                            <div
                              style={{
                                backgroundColor:
                                  (customData?.background && customData?.background) || 'transparent',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '1rem',
                              }}
                            >
                              <FluidImg maxWidth={'50%'} data={callout.media} />
                            </div>
                          ) : (
                            <div>image should be there</div>
                          )}
                          <InlineList style={{ width: '100%', padding: '1rem 2rem' }}>
                            {callout.ctas?.map((cta, i) => {
                              return (
                                <li key={i}>
                                  {!!cta.heading && <h2>{cta.heading}</h2>}
                                  {callout.preContentNode && (
                                    <MDXRenderer>{callout.preContentNode.childMdx.body}</MDXRenderer>
                                  )}
                                  <center>
                                    <Button to={cta.url}>{cta.text}</Button>
                                  </center>
                                </li>
                              );
                            })}
                          </InlineList>
                        </>
                      )}
                    </Card>
                  </Column>
                </Grid>
              </Card>
            );
          })}
        </Section>
      </JourneyContainer>
    </MDXProvider>
  );
}
